<template>
  <div class="ui-card-product" @click.prevent="onClick">
    <figure class="ui-card-product__figure" v-if="img">
      <img class="ui-card-product__img" :src="img" alt="" />
    </figure>
    <div class="ui-card-product__company" v-if="company">
      {{ company }}
    </div>
    <div class="ui-card-product__title" v-if="title">
      {{ title }}
    </div>
    <div class="ui-card-product__subtitle" v-if="subtitle">
      {{ subtitle }}
    </div>
    <div class="ui-card-product__footer">
      <div class="ui-card-product__price">
        <slot name="price" v-if="showPrice()"
          >{{ formatPrice(price) }} <sup>{{ currency }}</sup
          ><small v-if="showWeightUnit()">/ {{ weightUnit }}</small></slot
        >
        <slot name="priceRange" v-if="showPriceRange()">
          <span
            v-if="
              typeof priceRange !== 'undefined' &&
              typeof priceRange[0] !== 'undefined'
            "
            >{{ formatPrice(priceRange[0]) }} <sup>{{ currency }}</sup
            ><small v-if="showWeightUnit()">/ {{ weightUnit }}</small>
          </span>
          <span
            v-if="
              typeof priceRange !== 'undefined' &&
              typeof priceRange[1] !== 'undefined'
            "
          >
            - {{ formatPrice(priceRange[1]) }} <sup>{{ currency }}</sup
            ><small v-if="showWeightUnit()">/ {{ weightUnit }}</small></span
          >
        </slot>
      </div>
      <div class="ui-card-product__info">
        <slot name="info" v-if="showInfo()">{{ info }}</slot>
        <span v-if="showInfo()"> | </span
        ><slot name="info">{{ $t("family.market.product.vatexcl") }}</slot>
      </div>
    </div>
  </div>
</template>

<script>
import { formatPrice } from "../../../helpers/common";

export default {
  name: "MarketProductCard",
  props: {
    id: { type: Number, required: true },
    img: { type: String },
    company: { type: String, required: false },
    title: { type: String, required: false },
    subtitle: { type: String, required: false },
    info: { type: String, default: "" },
    price: { type: Number, required: false },
    priceRange: { type: Array, required: false },
    currency: { type: String, required: true },
    byWeight: { type: Boolean, default: false },
    weightUnit: { type: String, default: "" },
  },
  methods: {
    onClick() {
      this.$emit("onClick", this.id);
    },
    showInfo() {
      return this.info && typeof this.priceRange === "undefined";
    },
    showPrice() {
      return typeof this.price !== "undefined";
    },
    showPriceRange() {
      return (
        typeof this.price === "undefined" &&
        typeof this.priceRange !== "undefined"
      );
    },

    showWeightUnit() {
      return this.byWeight === true && this.weightUnit !== "";
    },

    formatPrice(value) {
      return formatPrice(value).format();
    },
  },
};
</script>
