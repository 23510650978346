<template>
  <div class="ui-orders__wrapper-3">
    <aside class="ui-orders__sidebar">
      <div class="overflow-hidden">
        <CRow>
          <CCol col="auto">
            <h3>{{ $t("role.orders.rounds.nav.ddmp") }}</h3>
          </CCol>
          <CCol col="auto">
            <OrdersLegend :statusMap="statusMap" />
          </CCol>
        </CRow>

        <CRow class="justify-content-between">
          <CCol col="auto">
            <CButton
              color="primary"
              @click="onAllWithdrawn"
              :disabled="!canView(PERMS.ORDERS_EDIT)"
              >{{ $t("role.orders.rounds.ddmp.allCollectedBtn") }}</CButton
            >
          </CCol>

          <CCol col="auto">
            <OrdersPrints
              class="mb-3"
              :prints="round.prints"
              :types="['wh_producer', 'wh_list']"
              :title="$t('role.orders.rounds.ddmp.printCollectionPlanBtn')"
            />
          </CCol>
        </CRow>
      </div>

      <div class="d-flex justify-content-center" v-if="loading">
        <CSpinner color="info" />
      </div>
      <div v-else>
        <CCard
          class="card-selectable mb-2"
          v-for="record in producers"
          :key="record.id"
          :class="cardClasses(record)"
        >
          <CCardBody @click.prevent="selectProducer(record)" class="p-2">
            <CRow class="justify-content-between align-items-center mb-1">
              <CCol>
                <div class="text-truncate">
                  P{{ producerId(record) }}
                  <strong>{{ producerName(record) }}</strong>
                </div>
              </CCol>
              <CCol col="auto">
                <div class="d-flex">
                  <span class="mr-2">
                    {{ $t("role.orders.rounds.ddmp.from") }}
                    {{ producerDeliveryDate(record, "dd-MM") }}
                    {{ $t("role.orders.rounds.ddmp.to") }}
                    {{ producerDeliveryDate(record, "HH:mm") }}
                  </span>
                  <dropdown-menu
                    :items="getActions(record)"
                    :select="(e) => onActionSelect(e, record)"
                    @click.native.stop
                    ><font-awesome-icon icon="ellipsis-h" />
                  </dropdown-menu>
                </div>
              </CCol>
            </CRow>
            <CProgress>
              <CProgressBar
                v-for="progress in ddmpProgress(record)"
                :key="progress.id"
                :color="`orders-${progress.color}`"
                :value="progress.percentage"
                >{{ progress.value }}</CProgressBar
              >
            </CProgress>
            <CRow class="justify-content-between">
              <CCol col="auto">
                {{ producerProdsCount(record) }}
                {{ $t("role.orders.rounds.ddmp.articles") }} -
                {{ producerOrdersCount(record) }}
                {{ $t("role.orders.rounds.ddmp.from") }}
                <span
                  v-if="producerOrdersNotWeighed(record) > 0"
                  class="text-warning ml-2"
                >
                  <font-awesome-icon icon="balance-scale" />
                  {{ producerOrdersNotWeighed(record) }}
                  {{ $t("role.orders.rounds.notWeighed") }}
                </span>
                <OrderIncidentBadge
                  class="ml-2"
                  :total="record.incidents"
                  :open="record.incidents_open"
                />
              </CCol>
              <CCol col="auto"
                ><strong
                  >{{ producerTotPrice(record)
                  }}<sup>{{ currencySymbol }}</sup></strong
                ></CCol
              >
            </CRow>
          </CCardBody>
        </CCard>
        <CCard class="mb-2" v-if="!producers.length && !loading">
          <CCardBody> {{ $t("role.orders.rounds.ddmp.noResults") }} </CCardBody>
        </CCard>
      </div>
    </aside>
    <main class="ui-orders__main">
      <Detail
        v-if="producerActive"
        :producer="producerActive"
        :parentIsLoading="loading"
      />
    </main>
  </div>
</template>

<script>
import { Query } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../../../../ds";
import Detail from "./Detail";
import OrdersPrints from "../../../share/OrdersPrints";
import OrdersLegend from "../../../share/OrdersLegend";
import OrderIncidentBadge from "../../../share/OrderIncidentBadge";
import orderManagementMixin from "../../../../../../mixins/orderManagement";
import {
  INCIDENT_ENTITY_TYPE,
  INCIDENT_TYPE,
  ORDER_ACTION,
  producersProgressMap,
} from "../../../../../../config/global";
import EventBus from "../../../../../../helpers/EventBus";
import { mapGetters, mapState } from "vuex";
import { buildPredicate } from "../../../../../../helpers/common";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ViewDdmp",

  mixins: [orderManagementMixin],

  components: {
    Detail,
    OrdersPrints,
    OrdersLegend,
    OrderIncidentBadge,
  },

  data() {
    return {
      PERMS,
      loading: false,
      producers: [],
      producerActive: null,
    };
  },

  watch: {
    round() {
      this.init();
    },
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),

    ...mapState("rounds", ["round"]),

    statusMap() {
      return producersProgressMap(this.$i18n);
    },
  },

  mounted() {
    this.init();
    EventBus.$on("orders:refresh", this.init);
    EventBus.$on("orders:applyFilters", this.init);
    EventBus.$on("orders:priceUpdate", this.onPriceUpdate);
  },

  beforeDestroy() {
    EventBus.$off("orders:refresh", this.init);
    EventBus.$off("orders:applyFilters", this.init);
    EventBus.$off("orders:priceUpdate", this.onPriceUpdate);
  },

  methods: {
    init() {
      this.getProducers();
    },

    getActions(record) {
      const canEdit = this.canView(PERMS.ORDERS_EDIT);
      const canEditDirect = canEdit && record.ddmp.delivery_type != 3;
      // console.log(canEditDirect)
      return [
        {
          id: ORDER_ACTION.FILTER,
          text: this.$t("role.orders.rounds.ddmp.orderActions.filter"),
        },
        {
          id: ORDER_ACTION.DONE,
          text: this.$t("role.orders.rounds.ddmp.orderActions.allPIckedUp"),
          disabled: !canEditDirect,
        },
        {
          id: ORDER_ACTION.UNDONE,
          text: this.$t("role.orders.rounds.ddmp.orderActions.allNotPickedUp"),
          disabled: !canEditDirect,
        },
        {
          id: ORDER_ACTION.INCIDENT,
          text: this.$t("role.orders.rounds.ddmp.orderActions.openIncident"),
          disabled: !canEdit,
        },
        {
          id: ORDER_ACTION.CANCELLATION,
          text: this.$t("role.orders.rounds.ddmp.orderActions.cancelAll"),
          disabled: !canEdit,
        },
      ];
    },

    getFilterQuery() {
      const { keyword } = this.$store.state.rounds.filters;

      const map = filtersMap("ddmp.main.filters");

      let predicate = buildPredicate(
        null,
        map,
        this.$store.state.rounds.filters
      );

      let query = predicate ? new Query().where(predicate) : new Query();

      const searchFields = filtersMap("ddmp.main.search", []);
      if (keyword && searchFields.length > 0) {
        query = query.search(keyword, searchFields);
      }

      return query;
    },

    getProducers() {
      const self = this;
      const roleId = this.$store.state.role.id;
      const roundId = this.$store.state.rounds.round.id;
      const endpoint = filtersMap("ddmp.main.endpoint");
      const dm = GetDataManagerNew(endpoint, [roleId, roundId]);
      const query = this.getFilterQuery();
      self.loading = true;
      dm.executeQuery(query)
        .then((response) => {
          self.producers = response.result;
          self.loading = false;

          let selected = null;

          if (self.producerActive) {
            selected =
              self.producers.find(
                (item) => item.id === self.producerActive.id
              ) || null;
          }

          if (selected === null && self.producers.length > 0) {
            selected = self.producers[0];
          }

          self.selectProducer(selected);
        })
        .catch((response) => {
          console.error(response);
          self.$store.dispatch("toaster/add", {
            title: this.$t("common.editor.messages.ko.title"),
            text: $t("common.general.loadingError"),
            color: "info",
            autohide: true,
          });
          self.loading = false;
        });
    },

    selectProducer(producer) {
      this.producerActive = producer;
    },

    cardClasses(record) {
      const isSelected =
        this.producerActive && this.producerActive.id === record.id;
      const haveError = this.producerDeliveryDateWarning(record);
      const isDirect = record.ddmp.delivery_type == 3;
      // console.log(record.ddmp.delivery_type)
      return {
        "card-selected": isSelected,
        "card-error": haveError,
        "card-direct": isDirect,
      };
    },

    onActionSelect(args, record) {
      const ddmps = [record.id];

      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };

      if (args.item.id === ORDER_ACTION.FILTER) {
        const entity = { id: record.id, label: this.producerName(record) };
        this.$store.commit("rounds/updateProducerId", entity);
        this.init();
      }

      if (args.item.id === ORDER_ACTION.DONE) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("ddmp.statusCode.next"),
            ddmps,
          })
          .then(onSuccess);
      }

      if (args.item.id === ORDER_ACTION.UNDONE) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("ddmp.statusCode.prev"),
            ddmps,
          })
          .then(onSuccess);
      }

      if (args.item.id === ORDER_ACTION.INCIDENT) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [{ type: INCIDENT_ENTITY_TYPE.DDMPS, items: [record] }],
          type: INCIDENT_TYPE.INCIDENT,
          show: true,
        });
      }

      if (args.item.id === ORDER_ACTION.CANCELLATION) {
        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          entities: [{ type: INCIDENT_ENTITY_TYPE.DDMPS, items: [record] }],
          type: INCIDENT_TYPE.CANCELLATION,
          show: true,
        });
      }
    },

    onAllWithdrawn() {
      const ddmps = this.producers.map((item) => item.id);
      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };
      this.$store
        .dispatch("rounds/updateStatusBatch", {
          status: filtersMap("ddmp.statusCode.next"),
          ddmps,
        })
        .then(onSuccess);
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },

    onPriceUpdate(payload) {
      const { ddmp } = payload.response;
      const idx = this.producers.findIndex((item) => item.id === ddmp.id);
      if (idx !== undefined) {
        this.producers[idx] = {
          ...this.producers[idx],
          tot_price: ddmp.price,
          tot_price_p: ddmp.credit_producer,
          need_w: ddmp.need_w,
        };
        this.producers = cloneDeep(this.producers);
      }
    },
  },
};
</script>
