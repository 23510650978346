<template>
  <div>
    <CRow class="justify-content-between mb-3">
      <CCol sm="auto"
        ><div class="ui-type-display-lg">
          {{ $t("role.orders.tour.title") }}
        </div></CCol
      >
    </CRow>
    <div>
      <section class="mb-5">
        <CCard>
          <CCardBody>
            <CRow class="mb-3">
              <CCol>
                <CInputCheckbox
                  class="ml-1"
                  :label="$t('role.orders.tour.showToursWithOpenIncidents')"
                  inline
                  custom
                  :checked.sync="roundsWithOpenIncidents"
                />
              </CCol>
              <CCol>
                <OrdersLegend
                  :statusMap="statusMap"
                  class="d-flex justify-content-between"
                />
              </CCol>
            </CRow>

            <div class="d-flex justify-content-center m-3" v-if="loading">
              <CSpinner color="info" />
            </div>

            <ejs-grid ref="grid" :dataSource="rows" v-show="!loading">
              <e-columns>
                <e-column
                  field="id"
                  :headerText="$t('role.orders.tour.table.header.closingDate')"
                  isPrimaryKey="{true}"
                  :template="closeTemplate"
                  width="200"
                ></e-column>
                <e-column
                  :template="ordersTemplate"
                  :headerText="$t('role.orders.tour.table.header.orders')"
                  width="220"
                ></e-column>
                <e-column :template="progressTemplate" headerText=""></e-column>
                <e-column
                  :headerText="$t('role.orders.tour.table.header.producers')"
                  :template="producersTemplate"
                  width="200"
                ></e-column>
                <e-column
                  :headerText="$t('role.orders.tour.table.header.incidents')"
                  :template="incidentsTemplate"
                  width="200"
                ></e-column>
                <e-column
                  headerText=""
                  :template="actionsTemplate"
                  width="100"
                ></e-column>
              </e-columns>
            </ejs-grid>
          </CCardBody>
        </CCard>
      </section>
    </div>
  </div>
</template>

<script>
import { Query, Predicate } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../../ds";
import OrderRoundClose from "./partials/list/OrderRoundClose";
import OrderRoundOrders from "./partials/list/OrderRoundOrders";
import OrderRoundProgress from "./partials/list/OrderRoundProgress";
import OrderRoundIncidents from "./partials/list/OrderRoundIncidents";
import OrderRoundProducers from "./partials/list/OrderRoundProducers";
import OrderRoundActions from "./partials/list/OrderRoundActions";
import OrdersLegend from "../share/OrdersLegend";
import { roundListProgressMap } from "../../../../config/global";

export default {
  name: "OrdersRoundsList",

  components: {
    OrdersLegend,
  },

  data() {
    return {
      loading: false,
      rows: [],
      roundsWithOpenIncidents: false,

      closeTemplate: () => {
        return { template: OrderRoundClose };
      },

      ordersTemplate: () => {
        return { template: OrderRoundOrders };
      },

      progressTemplate: () => {
        return { template: OrderRoundProgress };
      },

      producersTemplate: () => {
        return { template: OrderRoundProducers };
      },

      incidentsTemplate: () => {
        return { template: OrderRoundIncidents };
      },

      actionsTemplate: () => {
        return { template: OrderRoundActions };
      },
    };
  },

  computed: {
    statusMap() {
      return roundListProgressMap(this.$i18n);
    },
  },

  mounted() {
    this.init();
  },

  watch: {
    roundsWithOpenIncidents() {
      this.loadData();
    },
  },

  methods: {
    init() {
      this.loadData();
    },

    loadData() {
      const roleId = this.$store.state.role.id;
      const dm = GetDataManagerNew("role_market_orders_round", [roleId]);
      let predicate = new Predicate("close", "equal", false);

      if (this.roundsWithOpenIncidents) {
        predicate = predicate.and("incidents_open", "greaterthan", 0);
      }

      const query = new Query().where(predicate);

      this.loading = true;
      dm.executeQuery(query)
        .then((response) => {
          this.rows = response.result;
          this.loading = false;
        })
        .catch((response) => {
          this.$store.dispatch("toaster/add", {
            title: this.$t("common.editor.messages.ko.title"),
            text: $t("common.general.loadingError"),
            color: "info",
            autohide: true,
          });
          this.loading = false;
        });
    },
  },
};
</script>
