<template>
  <div>
    {{ getProductCount() }}
    {{ $t("role.orders.rounds.ddmi.details.table.products") }},
    {{ getProducertCount() }} {{ $t("role.orders.tour.table.producers") }}
  </div>
</template>

<script>
import get from "lodash/get";

export default {
  name: "OrdersTableInfo",

  methods: {
    getProductCount() {
      return get(this.data, `tot_prods`);
    },

    getProducertCount() {
      return get(this.data, `tot_producers`);
    },
  },
};
</script>
