import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAddressBook,
  faArrowLeft,
  faBalanceScale,
  faCalendar,
  faCamera,
  faCheckCircle,
  faChevronLeft,
  faCopy,
  faEdit,
  faEllipsisH,
  faExclamationTriangle,
  faFlag,
  faList,
  faLock,
  faMapMarkerAlt,
  faMoneyCheckAlt,
  faPhoneAlt,
  faQuestionCircle,
  faSearch,
  faShoppingCart,
  faStore,
  faSync,
  faTh,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faTruck,
  faUserSecret,
  faWarehouse,
  faBug,
} from "@fortawesome/free-solid-svg-icons";

import { faHandshake } from "@fortawesome/free-regular-svg-icons";

const initialize = (vue) => {
  library.add(
    faAddressBook,
    faArrowLeft,
    faBalanceScale,
    faCalendar,
    faCamera,
    faCheckCircle,
    faChevronLeft,
    faCopy,
    faEdit,
    faEllipsisH,
    faExclamationTriangle,
    faFlag,
    faHandshake,
    faList,
    faLock,
    faMapMarkerAlt,
    faMoneyCheckAlt,
    faPhoneAlt,
    faQuestionCircle,
    faSearch,
    faShoppingCart,
    faStore,
    faSync,
    faTh,
    faTimes,
    faTimesCircle,
    faTrashAlt,
    faTruck,
    faUserSecret,
    faWarehouse,
    faBug
  );
  vue.component("font-awesome-icon", FontAwesomeIcon);
};

export default initialize;
