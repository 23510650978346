<template>
  <div class="ui-orders__wrapper-3">
    <aside class="ui-orders__sidebar">
      <div class="overflow-hidden mb-3">
        <CRow>
          <CCol col="auto">
            <h3>{{ $t("role.orders.manage.incidents.title") }}</h3>
          </CCol>
          <CCol col="auto">
            <OrdersLegend :statusMap="statusMap" />
          </CCol>
        </CRow>
      </div>

      <div class="d-flex justify-content-center" v-if="loading">
        <CSpinner color="info" />
      </div>
      <div v-else>
        <CInputCheckbox
          class="ml-1"
          :label="$t('role.orders.manage.incidents.visualizeClosedIncidents')"
          inline
          custom
          :checked.sync="showClosedIncidents"
        />
        <CCard
          class="card-selectable mb-2"
          v-for="incident in incidents"
          :key="incident.id"
          :class="cardClasses(incident)"
        >
          <CCardBody @click.prevent="selectIncident(incident)" class="p-2">
            <CRow class="justify-content-between align-items-center">
              <CCol>
                <div class="text-truncate">
                  S{{ incidentId(incident) }}
                  <strong>{{ incidentFamilyName(incident) }}</strong>
                </div>
              </CCol>
              <CCol col="auto">
                <div class="d-flex">
                  <span class="mr-2">
                    {{ $t("role.orders.manage.incidents.openedOn") }}
                    {{ incidentDate(incident, "dd-MM") }}
                    {{ $t("role.orders.manage.incidents.at") }}
                    {{ incidentDate(incident, "HH:mm") }}
                  </span>
                  <font-awesome-icon
                    icon="lock"
                    class="text-warning"
                    v-if="incident.close"
                  />
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol
                ><div class="text-truncate">
                  <span
                    class="ui-type-caption text-gray-600"
                    v-html="stripHtml(incidentMessage(incident))"
                  /></div
              ></CCol>
            </CRow>
            <CProgress>
              <CProgressBar
                v-for="progress in incidentProgress(incident)"
                :key="progress.id"
                :color="`orders-${progress.color}`"
                :value="progress.percentage"
                >{{ progress.value }}</CProgressBar
              >
            </CProgress>
            <CRow class="justify-content-between">
              <CCol col="auto">
                {{ incidentProdsCount(incident) }}
                {{ $t("role.orders.manage.incidents.articles") }} -
                {{ incidentOrdersCount(incident) }}
                {{ $t("role.orders.manage.incidents.orders") }}
              </CCol>
              <CCol col="auto"
                ><strong
                  >{{ incidentPrice(incident)
                  }}<sup>{{ currencySymbol }}</sup></strong
                ></CCol
              >
            </CRow>
          </CCardBody>
        </CCard>
        <CCard class="mb-2" v-if="!incidents.length && !loading">
          <CCardBody>
            {{ $t("role.orders.manage.incidents.noResults") }}
          </CCardBody>
        </CCard>
      </div>
    </aside>
    <main class="ui-orders__main">
      <Detail
        v-if="incidentActive"
        :incident="incidentActive"
        :parentIsLoading="loading"
      />
    </main>
  </div>
</template>

<script>
import { Query } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../../../../ds";
import Detail from "./Detail";
import OrdersLegend from "../../../share/OrdersLegend";
import orderManagementMixin from "../../../../../../mixins/orderManagement";
import { incidentProgressMap } from "../../../../../../config/global";
import EventBus from "../../../../../../helpers/EventBus";
import { mapGetters, mapState } from "vuex";
import {
  addAndPredicateFilter,
  buildPredicate,
} from "../../../../../../helpers/common";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";

export default {
  name: "ViewIncident",

  mixins: [orderManagementMixin],

  components: {
    Detail,
    OrdersLegend,
  },

  data() {
    return {
      PERMS,
      loading: false,
      incidents: [],
      incidentActive: null,
      showClosedIncidents: false,
    };
  },

  watch: {
    round() {
      this.init();
    },

    showClosedIncidents() {
      this.getIncidents();
    },
  },

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),

    ...mapState("rounds", ["round"]),

    statusMap() {
      return incidentProgressMap(this.$i18n);
    },
  },

  mounted() {
    this.init();
    EventBus.$on("orders:refresh", this.init);
    EventBus.$on("orders:applyFilters", this.init);
  },

  beforeDestroy() {
    EventBus.$off("orders:refresh", this.init);
    EventBus.$off("orders:applyFilters", this.init);
  },

  methods: {
    init() {
      this.getIncidents();
    },

    getFilterQuery() {
      const { keyword } = this.$store.state.rounds.filters;

      const map = filtersMap("incident.main.filters");
      let predicate = null;

      predicate = buildPredicate(
        predicate,
        map,
        this.$store.state.rounds.filters
      );

      if (!this.showClosedIncidents) {
        predicate = addAndPredicateFilter(predicate, "close", "equal", false);
      }

      let query = predicate ? new Query().where(predicate) : new Query();

      const searchFields = filtersMap("incident.main.search", []);
      if (keyword && searchFields.length > 0) {
        query = query.search(keyword, searchFields);
      }

      return query;
    },

    getIncidents() {
      const self = this;
      const roleId = this.$store.state.role.id;
      const endpoint = filtersMap("incident.main.endpoint");
      const dm = GetDataManagerNew(endpoint, [roleId]);
      const query = this.getFilterQuery();
      self.loading = true;
      dm.executeQuery(query)
        .then((response) => {
          self.incidents = response.result;
          self.loading = false;

          let selected = null;

          if (self.incidentActive) {
            selected =
              self.incidents.find(
                (item) => item.id === self.incidentActive.id
              ) || null;
          }

          if (selected === null && self.incidents.length > 0) {
            selected = self.incidents[0];
          }

          self.selectIncident(selected);
        })
        .catch((response) => {
          console.error(response);
          self.$store.dispatch("toaster/add", {
            title: this.$t("common.editor.messages.ko.title"),
            text: $t("common.general.loadingError"),
            color: "info",
            autohide: true,
          });
          self.loading = false;
        });
    },

    selectIncident(incident) {
      this.incidentActive = incident;
    },

    cardClasses(record) {
      const isSelected =
        this.incidentActive && this.incidentActive.id === record.id;
      const haveError = this.producerDeliveryDateWarning(record);
      return { "card-selected": isSelected, "card-error": haveError };
    },
  },
};
</script>
